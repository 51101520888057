import { FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, NavLink } from "react-router-dom";
import { X } from "@styled-icons/bootstrap/X";

import { useContextHospi } from "context/ContextHospi";
import { LOGIN_ROUTE, REGISTER_ROUTE } from "utils/constants";

import { ProfileNavbarContainer } from "./styles";

const MobileProfileNavbar: FC<{
  setOpen: Dispatch<SetStateAction<boolean>>;
  children?: never;
}> = ({ setOpen }) => {
  const { t, i18n } = useTranslation("header");
  const { cognitoUser } = useContextHospi();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ProfileNavbarContainer
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        className="close"
        onClick={handleClick}
        aria-label="Close signing-in navbar"
      >
        <X size="1.5rem" />
      </button>
      {!cognitoUser && (
        <>
          <li>
            <NavLink
              to={generatePath(LOGIN_ROUTE, {
                lng: i18n.language
              })}
              onClick={handleClick}
            >
              {t("menu.signIn")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={generatePath(REGISTER_ROUTE, {
                lng: i18n.language
              })}
              onClick={handleClick}
            >
              {t("menu.signUp")}
            </NavLink>
          </li>
        </>
      )}
    </ProfileNavbarContainer>
  );
};

export default MobileProfileNavbar;
