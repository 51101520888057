import styled from "styled-components/macro";
import { ContainerPopupMenu } from "../../Header/styles";

export const PublicNavbarContainer = styled(ContainerPopupMenu)`
  left: 0;
  right: auto;
  z-index: 20;
  padding-top: 3.25em;
  overflow-x: auto;
  border-top-left-radius: 0;

  & a {
    color: ${({ theme }) => theme.colors.text};
    padding: 0.5em 1em;
    &.active {
      font-weight: ${({ theme }) => theme.font.weightSemiMedium};
    }
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  & button.close {
    right: auto;
    left: 1em;
  }
  & button.back {
    right: 0.8571em;
    left: auto;
  }
  & li {
    list-style-type: none;
    a.active:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media ${({ theme }) => theme.media.tablet} {
    right: auto;
    top: 0;
    border-top-right-radius: 12px;
  }
  @media ${({ theme }) => theme.media.phone} {
    right: auto;
    top: 0;
    border-top-right-radius: 12px;
  }
`;
