import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { ContextHospiProvider } from "./context/ContextHospi";
import { HospiRouter } from "./components/HospiRouter";
import TagManager from "react-gtm-module";
import { SocketProvider } from "./context/SocketProvider";
import { ConfigProvider as AntConfigProvider } from "antd";
import { theme } from "./styles/theme";
import { NotifierProvider } from "./context/ContextNotifier";
import { ChatProvider } from "./context/ContextChat/ContextChat";
import { useUpdateAppHeightCssVariable } from "./utils/dom";
import queryClient from "./stores/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { PublicAccommodationProvider } from "./context/ContextPublicAccommodation";
import { ConfirmModalProvider } from "./context/ContextConfirmModal";
import { ContractsSignaturesProvider } from "./context/ContextContractsSignatures";
import { AutoTranslationsProvider } from "./context/ContextAutoTranslations";
import { StudentSearchesProvider } from "./context/ContextStudentSearches";

declare module "react" {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    fetchpriority?: "high" | "low" | "auto";
  }
}

const antdTheme = {
  token: {
    fontFamily: theme.font.family,
    colorPrimary: theme.colors.primary,
    fontSize: 16
  }
};

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const tagManagerArgs = {
  gtmId: "GTM-PP9F922",
  dataLayerName: "dataLayer",
  auth: process.env.REACT_APP_TAG_AUTH as string,
  preview: process.env.REACT_APP_TAG_ENV as string
};

// Declare the dataLayer object
window.dataLayer = window.dataLayer || [];

// Initialize GTM
TagManager.initialize(tagManagerArgs);

const App: FC = () => {
  useUpdateAppHeightCssVariable();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ContextHospiProvider>
          <SocketProvider>
            <ChatProvider>
              <StudentSearchesProvider>
                <AutoTranslationsProvider>
                  <AntConfigProvider theme={antdTheme}>
                    <ConfirmModalProvider>
                      <NotifierProvider>
                        <PublicAccommodationProvider>
                          <ContractsSignaturesProvider>
                            <HospiRouter />
                          </ContractsSignaturesProvider>
                        </PublicAccommodationProvider>
                      </NotifierProvider>
                    </ConfirmModalProvider>
                  </AntConfigProvider>
                </AutoTranslationsProvider>
              </StudentSearchesProvider>
            </ChatProvider>
          </SocketProvider>
        </ContextHospiProvider>
      </BrowserRouter>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  );
};

export default App;
