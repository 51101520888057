import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  NavLink,
  useNavigate,
  useLocation,
  generatePath
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import ReactCountryFlag from "react-country-flag";
import { List } from "@styled-icons/bootstrap/List";
import { ChevronRight } from "@styled-icons/bootstrap/ChevronRight";
import { ChevronLeft } from "@styled-icons/bootstrap/ChevronLeft";
import { X } from "@styled-icons/bootstrap/X";

import {
  HOST_HOUSE_WIZARD_ROUTE,
  HOST_WIZARD_ROUTE,
  STUDENT_WIZARD_ROUTE,
  ISO_LANGUAGES,
  STUDENT_PROFILE_VIEW_ROUTE,
  HOST_PROFILE_VIEW_ROUTE,
  BLOG_ROUTE
} from "utils/constants";
import { Logo } from "../Icons/Logo";
import { StyledButton } from "components/generic";
import { publicRoutes } from "../../routes";
import { useContextHospi } from "../../context/ContextHospi";
import { useIsMobile } from "../../utils/hooks/useWindowSize";
import MobilePublicNavbar from "../MobileNavbar/MobilePublicNavbar";
import MobileProfileNavbar from "../MobileNavbar/MobileProfileNavbar";
import { LangBubble } from "../LangBubble";
import {
  isChatPath,
  useGetHelpNavbarRoutes,
  useGetProfileMenuRoutes
} from "../../utils/navbar";
import useOnClickOutside from "../../utils/hooks/useOnClickOutside";
import { HelpNavbar } from "./HelpNavbar";
import { useChat } from "../../context/ContextChat/ContextChat";

import {
  PersonIcon,
  HeaderWrapper,
  HeaderContainer,
  StyledNavbar,
  NotAuthorizedUserContainer,
  ListWrapper,
  MenuItem,
  MobileLogoLink,
  Item,
  ItemText,
  AvatarImg,
  AuthButtons,
  ProfileNavbarIcons,
  StyledBurger,
  ContainerPopupMenu,
  PROFILE_NAVBAR_ICONS_SIZES,
  SecondaryNavbarContainer,
  LngButton
} from "./styles";
import { NavbarInfoContainer } from "../MobileNavbar/MobileStickyNavbar/styles";

export const Header = () => {
  const [isHelpNavbarShown, setIsHelpNavbarShown] = useState(false);
  const [isLangNavbarShown, setIsLangNavbarShown] = useState(false);
  const [isMobileHelpNavbarShown, setIsMobileHelpNavbarShown] = useState(false);
  const [isProfileNavbarShown, setIsProfileNavbarShown] = useState(false);
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const [registerQueryKey, setRegisterQueryKey] = useState<"h" | "s" | "">("");
  const navigate = useNavigate();
  const {
    cognitoUser,
    currentUserName,
    userProfile,
    isLoadingCognitoUser,
    isLoadingCurrentUser,
    logOut,
    notFinishedRequiredHouseWizard,
    currentUserRole
  } = useContextHospi();
  const {
    state: { chatCounters }
  } = useChat();
  const { t, i18n } = useTranslation("header");
  const isMobile = useIsMobile();
  const location = useLocation();
  const profileNavbarRoutes = useGetProfileMenuRoutes();
  const helpNavbarRoutes = useGetHelpNavbarRoutes();
  const profileNavbarRef = useRef<HTMLUListElement | null>(null);
  useOnClickOutside(profileNavbarRef, () => {
    setIsProfileNavbarShown(false);
    setIsMobileHelpNavbarShown(false);
    setIsLangNavbarShown(false);
  });

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    setRegisterQueryKey(
      location.pathname.includes("/hospita")
        ? "h"
        : location.pathname.includes("/students")
        ? "s"
        : ""
    );
  }, [location.pathname]);

  useEffect(() => {
    if (!isMobile) {
      setIsLeftMenuOpen(false);
      setIsRightMenuOpen(false);
      if (isMobileHelpNavbarShown) {
        setIsMobileHelpNavbarShown(false);
        setIsHelpNavbarShown(true);
      }
    } else {
      if (isHelpNavbarShown) {
        setIsMobileHelpNavbarShown(true);
        setIsHelpNavbarShown(false);
      }
    }
  }, [isMobile]);

  const unreadCount =
    currentUserRole === "host"
      ? (chatCounters?.requested ?? 0) + (chatCounters?.unread_accepted ?? 0)
      : chatCounters?.unread_accepted ?? 0;

  const changeLanguage = (lng: "gb" | "nl"): void => {
    navigate(
      {
        pathname: `/${lng}/${location.pathname.slice(4)}${location.search}`
      },
      { replace: true }
    );
    i18n.changeLanguage(lng.toLocaleLowerCase());
  };

  return (
    <HeaderWrapper id="hospi-header">
      {/* <Banner
                button={`${t("banner.button")} `}
                text={`${t("banner.text")} `}
                href="https://www.eventbrite.nl/e/tickets-hospi-housing-webinar-verhuur-tijdelijk-een-kamer-in-huis-405115741087?utm_campaign=post_publish&utm_medium=email&utm_source=eventbrite&utm_content=shortLinkNewEmail"
            /> */}
      <HeaderContainer>
        {isRightMenuOpen && (
          <MobileProfileNavbar setOpen={setIsRightMenuOpen} />
        )}
        {isLeftMenuOpen && <MobilePublicNavbar setOpen={setIsLeftMenuOpen} />}
        <StyledNavbar>
          {!isMobile ? (
            <>
              <NavLink to={`/${i18n.language}/`}>
                <Logo />
              </NavLink>
              <ListWrapper>
                {!cognitoUser ? (
                  publicRoutes
                    .filter(({ keyLocalize }) => keyLocalize !== "contact")
                    .map((item) => (
                      <MenuItem key={item.path}>
                        <NavLink
                          to={generatePath(item.path, { lng: i18n.language })}
                        >
                          {t(`menu.${item.keyLocalize}`)}
                        </NavLink>
                      </MenuItem>
                    ))
                ) : (
                  <>
                    {profileNavbarRoutes.map((item) => (
                      <MenuItem
                        key={`profileNavbarRoute${item.keyLocalize}`}
                        $isUserAuthorized={true}
                      >
                        <NavLink
                          style={{ position: "relative" }}
                          to={generatePath(item.path, {
                            lng: i18n.language,
                            id: isChatPath(item.path) ? "all" : ""
                          })}
                        >
                          {t(`subMenu.${item.keyLocalize}`)}
                          {unreadCount && item.keyLocalize === "chats" ? (
                            <NavbarInfoContainer className="desktopNavbarInfo">
                              <span className="navbarInfo">{unreadCount}</span>
                            </NavbarInfoContainer>
                          ) : null}
                          {/*{item.keyLocalize === "saved" && searches.length > 0 ? (*/}
                          {/*  <NavbarInfoContainer className="desktopNavbarInfo">*/}
                          {/*    <span className="navbarInfo">{searches.length + 1}</span>*/}
                          {/*  </NavbarInfoContainer>*/}
                          {/*) : null}*/}
                        </NavLink>
                      </MenuItem>
                    ))}
                    <HelpNavbar
                      isShown={isHelpNavbarShown}
                      setIsShown={setIsHelpNavbarShown}
                      setIsProfileNavbarShown={setIsProfileNavbarShown}
                    />
                  </>
                )}
              </ListWrapper>
              {!cognitoUser && (
                <NotAuthorizedUserContainer>
                  <LangBubble />
                  <AuthButtons>
                    <StyledButton
                      p="0.5625em 1.0625em"
                      fontSize="1rem"
                      outline
                      onClick={() =>
                        navigate(
                          `/${i18n.language}/login${
                            registerQueryKey ? `?m=${registerQueryKey}` : ""
                          }`
                        )
                      }
                    >
                      {t("menu.signIn")}
                    </StyledButton>
                    <StyledButton
                      p="0.5625em 1.0625em"
                      fontSize="1rem"
                      onClick={() =>
                        navigate(
                          `/${i18n.language}/register${
                            registerQueryKey ? `?m=${registerQueryKey}` : ""
                          }`
                        )
                      }
                    >
                      {t("menu.signUp")}
                    </StyledButton>
                  </AuthButtons>
                </NotAuthorizedUserContainer>
              )}
            </>
          ) : (
            <>
              {!!cognitoUser ? (
                <NavLink to={`/${i18n.language}/`}>
                  <Logo />
                </NavLink>
              ) : (
                <>
                  <StyledBurger
                    style={{
                      zIndex: isLeftMenuOpen || isRightMenuOpen ? 0 : 20
                    }}
                    onClick={() => {
                      setIsLeftMenuOpen(!isLeftMenuOpen);
                      setIsRightMenuOpen(false);
                    }}
                  >
                    <div />
                    <div />
                    <div />
                  </StyledBurger>
                  <MobileLogoLink to={`/${i18n.language}/`}>
                    <Logo />
                  </MobileLogoLink>
                  <PersonIcon
                    style={{
                      zIndex: isLeftMenuOpen || isRightMenuOpen ? 0 : 30
                    }}
                    size="2.1875rem"
                    onClick={() => {
                      setIsLeftMenuOpen(false);
                      setIsRightMenuOpen(!isRightMenuOpen);
                    }}
                  />
                </>
              )}
            </>
          )}
          {isLoadingCognitoUser ||
            (isLoadingCurrentUser && (
              <Skeleton.Button
                size="large"
                active
                style={{
                  width: `${
                    isMobile
                      ? PROFILE_NAVBAR_ICONS_SIZES.mobile.width
                      : PROFILE_NAVBAR_ICONS_SIZES.desktop.width
                  }rem`,
                  height: `${
                    isMobile
                      ? PROFILE_NAVBAR_ICONS_SIZES.mobile.height
                      : PROFILE_NAVBAR_ICONS_SIZES.desktop.height
                  }rem`
                }}
              />
            ))}
          {cognitoUser && (
            <ProfileNavbarIcons
              onClick={() => {
                if (!isProfileNavbarShown) setIsHelpNavbarShown(false);
                setIsProfileNavbarShown((prev) => !prev);
              }}
            >
              <List
                style={{ display: "inline-block" }}
                width={isMobile ? "1.8125rem" : "2.1875rem"}
              />
              {userProfile?.thumbnail_url && (
                <AvatarImg src={userProfile.thumbnail_url} alt="" />
              )}
              {(isProfileNavbarShown ||
                isMobileHelpNavbarShown ||
                isLangNavbarShown) && (
                <ContainerPopupMenu ref={profileNavbarRef}>
                  {(isMobile || isLangNavbarShown) && (
                    <button
                      className="close"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsProfileNavbarShown(false);
                        setIsMobileHelpNavbarShown(false);
                        setIsLangNavbarShown(false);
                      }}
                      aria-label="Close navbar"
                    >
                      <X size="1.5rem" />
                    </button>
                  )}
                  {(isMobileHelpNavbarShown || isLangNavbarShown) && (
                    <SecondaryNavbarContainer
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <button
                        aria-label="Go to profile navbar"
                        onClick={() => {
                          setIsMobileHelpNavbarShown(false);
                          setIsProfileNavbarShown(true);
                          setIsLangNavbarShown(false);
                        }}
                        className="back"
                      >
                        <ChevronLeft size="1rem" />
                      </button>
                      {isMobileHelpNavbarShown &&
                        helpNavbarRoutes.map((item, idx) => (
                          <Item
                            key={`mobileHelpNavbarItem${item.keyLocalize}`}
                            onClick={() => {
                              setIsMobileHelpNavbarShown(false);
                            }}
                          >
                            <NavLink
                              style={{
                                padding:
                                  idx === helpNavbarRoutes.length - 1
                                    ? "0.5em 1em 1em"
                                    : "0.5em 1em"
                              }}
                              to={generatePath(item.path, {
                                lng: i18n.language
                              })}
                            >
                              {t(`subMenu.${[item.keyLocalize]}`)}
                            </NavLink>
                          </Item>
                        ))}
                      {isLangNavbarShown &&
                        ISO_LANGUAGES.map((item) => (
                          <LngButton
                            $isActive={
                              item ===
                              (i18n.language === "gb" ? "en" : i18n.language)
                            }
                            key={`profileNavbarLang${item}`}
                            onClick={() => {
                              changeLanguage(item === "en" ? "gb" : item);
                              setIsLangNavbarShown(false);
                            }}
                          >
                            <ReactCountryFlag
                              alt=""
                              countryCode={item === "en" ? "GB" : item}
                              svg
                              style={{
                                width: "1.3125rem",
                                height: "1.3125rem"
                              }}
                            />
                            {t(`${item.toUpperCase()}`)}
                          </LngButton>
                        ))}
                    </SecondaryNavbarContainer>
                  )}
                  {isProfileNavbarShown && (
                    <>
                      <ItemText>
                        <p>
                          {!!currentUserName?.length
                            ? t(`subMenu.signedIn`)
                            : ""}
                        </p>
                        <p>{currentUserName}</p>
                      </ItemText>
                      <hr />
                      {userProfile ? (
                        <>
                          <Item className="buttonStyledItem">
                            <NavLink
                              to={generatePath(
                                currentUserRole === "host"
                                  ? HOST_PROFILE_VIEW_ROUTE
                                  : STUDENT_PROFILE_VIEW_ROUTE,
                                {
                                  lng: i18n.language
                                }
                              )}
                            >
                              {t(`subMenu.profile`)}
                            </NavLink>
                          </Item>
                          {notFinishedRequiredHouseWizard ? (
                            <Item className="buttonStyledItem">
                              <NavLink
                                to={generatePath(HOST_HOUSE_WIZARD_ROUTE, {
                                  lng: i18n.language,
                                  houseId:
                                    notFinishedRequiredHouseWizard.house_id,
                                  wizardType:
                                    notFinishedRequiredHouseWizard.wizard_type
                                })}
                              >
                                {t(`subMenu.finishHouseWizard`)}
                              </NavLink>
                            </Item>
                          ) : null}
                        </>
                      ) : (
                        <Item className="buttonStyledItem">
                          {currentUserRole ? (
                            <NavLink
                              to={generatePath(
                                currentUserRole === "student"
                                  ? STUDENT_WIZARD_ROUTE
                                  : HOST_WIZARD_ROUTE,
                                {
                                  lng: i18n.language
                                }
                              )}
                            >
                              {t(`subMenu.finishUserWizard`)}
                            </NavLink>
                          ) : (
                            ""
                          )}
                        </Item>
                      )}
                      {isMobile && (
                        <>
                          <Item className="buttonStyledItem">
                            <NavLink
                              to={generatePath(BLOG_ROUTE, {
                                lng: i18n.language
                              })}
                            >
                              {t(`subMenu.blog`)}
                            </NavLink>
                          </Item>
                          <Item
                            className="buttonItem"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsMobileHelpNavbarShown(true);
                              setIsProfileNavbarShown(false);
                            }}
                          >
                            <span>{t("subMenu.help")}</span>
                            <ChevronRight size="1rem" />
                          </Item>
                        </>
                      )}
                      <hr />
                      <Item
                        className="buttonItem"
                        onClick={() => {
                          logOut();
                        }}
                      >
                        {t("subMenu.signOut")}
                      </Item>
                      <hr />
                      <Item
                        className="buttonItem"
                        style={{ padding: "0.5em 1em 1em" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsLangNavbarShown(true);
                          setIsProfileNavbarShown(false);
                        }}
                      >
                        <span>{t("language")}</span>
                        <ChevronRight size="1rem" />
                      </Item>
                    </>
                  )}
                </ContainerPopupMenu>
              )}
            </ProfileNavbarIcons>
          )}
        </StyledNavbar>
      </HeaderContainer>
    </HeaderWrapper>
  );
};
