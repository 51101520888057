import { matchPath } from "react-router-dom";

import { hostRoutes, studentRoutes, TMenuRoute } from "routes";
import {
  BECOME_HOST_ROUTE,
  BLOG_ROUTE,
  CONTACT_ROUTE,
  FIND_ROOM_ROUTE,
  HOST_CHATS_ROUTE_ID,
  HOST_ROOMS_ROUTE,
  HOST_TENANTS_ROUTE,
  HOST_TIPS_ROUTE,
  STUDENT_CHATS_ROUTE_ID,
  STUDENT_MATCHES_ROUTE,
  STUDENT_ROOMS_ROUTE,
  STUDENT_SEARCHES_ROUTE,
  STUDENT_TIPS_ROUTE
} from "utils/constants";
import { useContextHospi } from "context/ContextHospi";
import { ThumbUpIcon } from "../components/Icons/ThumbUp";

const studentMenuRoutes = [
  STUDENT_ROOMS_ROUTE,
  STUDENT_SEARCHES_ROUTE,
  STUDENT_CHATS_ROUTE_ID,
  STUDENT_MATCHES_ROUTE
];

const hostMenuRoutes = [
  HOST_CHATS_ROUTE_ID,
  HOST_TENANTS_ROUTE,
  HOST_ROOMS_ROUTE
];

export const useGetHelpNavbarRoutes = (): TMenuRoute[] => {
  const { currentUserRole } = useContextHospi();

  if (!currentUserRole) return [];
  return [
    {
      path: BECOME_HOST_ROUTE,
      keyLocalize: "hosting"
    },
    {
      path: FIND_ROOM_ROUTE,
      keyLocalize: "findingHousing"
    },
    {
      path: currentUserRole === "host" ? HOST_TIPS_ROUTE : STUDENT_TIPS_ROUTE,
      keyLocalize: "faq"
    },
    { path: CONTACT_ROUTE, keyLocalize: "contact" }
  ];
};

export const useGetProfileMenuRoutes = (): TMenuRoute[] => {
  const { currentUserRole, userProfile } = useContextHospi();

  if (!currentUserRole) return [];
  let menuRoutesData: TMenuRoute[] = [];
  if (userProfile) {
    (currentUserRole === "host" ? hostMenuRoutes : studentMenuRoutes).forEach(
      (r) => {
        const routeData: TMenuRoute | undefined = (
          currentUserRole === "host" ? hostRoutes : studentRoutes
        ).find((navRoute) => {
          return Boolean(matchPath(r, navRoute.path));
        });
        if (routeData) menuRoutesData.push(routeData);
      }
    );
  }

  return menuRoutesData.concat({
    keyLocalize: "blog",
    icon: <ThumbUpIcon />,
    path: BLOG_ROUTE
  } as TMenuRoute);
};

export const isChatPath = (path: string): boolean => {
  return (
    !!matchPath(path, STUDENT_CHATS_ROUTE_ID) ||
    !!matchPath(path, HOST_CHATS_ROUTE_ID)
  );
};
