import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { X } from "@styled-icons/bootstrap/X";
import { ChevronRight } from "@styled-icons/bootstrap/ChevronRight";
import ReactCountryFlag from "react-country-flag";

import { publicRoutes } from "routes";
import { ISO_LANGUAGES } from "../../../utils/constants";

import { PublicNavbarContainer } from "./styles";
import { Item, LngButton } from "../../Header/styles";
import { ChevronLeft } from "@styled-icons/bootstrap/ChevronLeft";

type TMobilePublicNavbarProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  children?: never;
};

const MobilePublicNavbar: FC<TMobilePublicNavbarProps> = ({ setOpen }) => {
  const [isMobileLangNavbarShown, setIsMobileLangNavbarShown] = useState(false);
  const { t, i18n } = useTranslation("header");
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const changeLanguage = (lng: "gb" | "nl"): void => {
    navigate(
      {
        pathname: `/${lng}/${location.pathname.slice(4)}${location.search}`
      },
      { replace: true }
    );
    i18n.changeLanguage(lng.toLocaleLowerCase());
  };

  return (
    <PublicNavbarContainer
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button className="close" onClick={handleClick} aria-label="Close navbar">
        <X size="1.5rem" />
      </button>
      {!isMobileLangNavbarShown ? (
        <>
          {publicRoutes.map((item) => (
            <li key={item.path} onClick={handleClick}>
              <NavLink to={`/${i18n.language}${item.path.slice(5)}`}>
                {t(`menu.${item.keyLocalize}`)}
              </NavLink>
            </li>
          ))}
          <hr />
          <Item
            className="buttonItem"
            style={{ padding: "0.5em 1em 1em" }}
            onClick={() => {
              setIsMobileLangNavbarShown(true);
            }}
          >
            <span>{t("language")}</span>
            <ChevronRight size="1rem" />
          </Item>
        </>
      ) : (
        <>
          <button
            aria-label="Go to main navbar"
            onClick={() => {
              setIsMobileLangNavbarShown(false);
            }}
            className="back"
          >
            <ChevronLeft size="1rem" />
          </button>
          {ISO_LANGUAGES.map((item) => (
            <LngButton
              $isActive={
                item === (i18n.language === "gb" ? "en" : i18n.language)
              }
              key={`profileNavbarLang${item}`}
              onClick={() => {
                changeLanguage(item === "en" ? "gb" : item);
                setIsMobileLangNavbarShown(false);
                handleClick();
              }}
            >
              <ReactCountryFlag
                alt=""
                countryCode={item === "en" ? "GB" : item}
                svg
                style={{
                  width: "1.3125rem",
                  height: "1.3125rem"
                }}
              />
              {t(`${item.toUpperCase()}`)}
            </LngButton>
          ))}
        </>
      )}
    </PublicNavbarContainer>
  );
};
export default MobilePublicNavbar;
